import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Modal from "./modal";

const Top: React.FC = () => {
  const SERVICE_ID = "service_ow238xb";
  const TEMPLATE_ID = "template_f1649ml";
  const PUBLIC_KEY = "m5vh8N-Q_vGu6x2Fa";

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [allowContact, setAllowContact] = useState(false);

  const params = {
    name,
    mail,
    phoneNumber,
    subject,
    message,
    allowContact,
  };

  const clear = () => {
    setName("");
    setMail("");
    setPhoneNumber("");
    setSubject("");
    setMessage("");
  };

  const handleSubmit = () => {
    emailjs.send(SERVICE_ID, TEMPLATE_ID, params, PUBLIC_KEY).then(
      (res) => {
        clear();
        setShowModal(true);
      },
      (err) => {
        console.log("ERR", err);
      }
    );
  };

  return (
    <>
      <section className="pt-6 pb-20 bg-gray-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="mb-6">
            <div className="flex items-center justify-between px-6 py-3.5 bg-white border border-gray-100 rounded-full">
              <div className="w-auto">
                <div className="flex flex-wrap items-center">
                  <div className="w-auto">
                    <a href="/">
                      <img src="images/femtowork-logo.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center -m-4" id="mission">
            <div className="w-full md:w-auto p-4">
              <img className="mx-auto" src="images/device.png" />
            </div>
            <div className="w-full md:flex-1 p-4">
              <div className="flex flex-col justify-center items-center p-8 h-full text-center bg-white rounded-3xl">
                <div className="max-w-lg">
                  <span className="inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest">
                    OUR MISSION
                  </span>
                  <h1 className="font-heading mb-6 text-5xl text-gray-900 font-black tracking-tight">
                    <span>Build better services </span>
                    <span>faster than ever.</span>
                  </h1>
                  <p className="mb-8">
                    フェムトワークは革新的なテクノロジーと高度なエンジニアリングを結集し、スタートアップ企業、Webサービス、DX戦略を実現したいエンタープライズのお客様向けに先端サービスを提供しています。新規プロダクト開発支援やPoC（概念実証）の実施、MVP（最小限の市場投入製品）プロダクトの開発支援も行っております。お客様のニーズに合わせたカスタムソリューションの開発や効率化を通じて、ビジネスの成長と競争力の向上を支援します。
                  </p>
                  <div className="flex flex-wrap justify-center -m-2">
                    <div className="w-full md:w-auto p-2">
                      <a
                        className="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full"
                        href="#contact"
                      >
                        Contact us
                      </a>
                    </div>
                    <div className="w-full md:w-auto p-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 bg-gray-50 overflow-hidden" id="solutions">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 md:px-16 bg-white rounded-3xl">
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-1/2 p-8">
                <img
                  className="mx-auto md:ml-0 rounded-3xl"
                  src="images/business.png"
                />
              </div>
              <div className="w-full md:w-1/2 p-8">
                <div className="md:max-w-md">
                  <span className="inline-block mb-5 text-sm text-blue-500 font-bold uppercase tracking-widest">
                    SOLUTION
                  </span>
                  <h2 className="font-heading mb-4 text-4xl text-gray-900 font-black tracking-tight">
                    <span>Product development</span>
                  </h2>
                  <p className="mb-16">
                    私たちはシンプルかつ効果的なプロダクト開発を得意とし、お客様のビジネスに高い価値をもたらすことができます。品質の高い成果物を提供します。
                  </p>
                  <div className="flex flex-wrap -m-2">
                    <div className="w-auto p-2">
                      <div className="flex flex-wrap px-3 py-2 bg-gray-100 rounded-full">
                        <div className="w-auto mr-2 pt-1">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                              fill="#3B82F6"
                            />
                          </svg>
                        </div>
                        <div className="flex-1">
                          <p className="text-sm text-gray-900 font-bold">
                            Webサービス開発
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-auto p-2">
                      <div className="flex flex-wrap px-3 py-2 bg-gray-100 rounded-full">
                        <div className="w-auto mr-2 pt-1">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                              fill="#3B82F6"
                            />
                          </svg>
                        </div>
                        <div className="flex-1">
                          <p className="text-sm text-gray-900 font-bold">
                            ネイティブアプリ開発
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-auto p-2">
                      <div className="flex flex-wrap px-3 py-2 bg-gray-100 rounded-full">
                        <div className="w-auto mr-2 pt-1">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                              fill="#3B82F6"
                            />
                          </svg>
                        </div>
                        <div className="flex-1">
                          <p className="text-sm text-gray-900 font-bold">
                            PoC（コンセプト検証）支援
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-auto p-2">
                      <div className="flex flex-wrap px-3 py-2 bg-gray-100 rounded-full">
                        <div className="w-auto mr-2 pt-1">
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                              fill="#3B82F6"
                            />
                          </svg>
                        </div>
                        <div className="flex-1">
                          <p className="text-sm text-gray-900 font-bold">
                            パフォーマンス改善
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 bg-gray-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 md:px-16 bg-white rounded-3xl">
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-1/2 p-8">
                <div className="md:max-w-md">
                  <span className="inline-block mb-5 text-sm text-blue-500 font-bold uppercase tracking-widest">
                    SOLUTION
                  </span>
                  <h2 className="font-heading mb-10 text-4xl text-gray-900 font-black tracking-tight">
                    <span>Engineer team consulting</span>
                  </h2>
                  <div className="flex flex-wrap -m-2.5">
                    <div className="w-full p-2.5">
                      <div className="flex flex-wrap -m-3">
                        <div className="w-auto p-3">
                          <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z"
                                fill="#3B82F6"
                              />
                              <path
                                d="M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z"
                                fill="#3B82F6"
                              />
                              <path
                                d="M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z"
                                fill="#3B82F6"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="flex-1 p-3">
                          <h3 className="font-heading mb-2 text-xl text-gray-900 font-black">
                            エンジニア採用支援
                          </h3>
                          <p className="text-sm text-gray-700">
                            私たちのアプローチは、単にスキルだけではなく、エンジニアが抱えるキャリア目標と興味と、お客様の事業、エンジニア組織の提供できる機会のマッチングに注目して戦略を立案します。
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full p-2.5">
                      <div className="flex flex-wrap -m-3">
                        <div className="w-auto p-3">
                          <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.14 7.72C19.43 8.47 20.15 9 21 9C22.1 9 23 8.1 23 7C23 5.9 22.1 5 21 5C20.15 5 19.43 5.53 19.14 6.28C19.09 6.27 19.05 6.25 19 6.25H15V5.5C15 4.68 14.32 4 13.5 4H10.5C9.68 4 9 4.68 9 5.5V6.25H5C4.95 6.25 4.91 6.27 4.86 6.28C4.57 5.53 3.85 5 3 5C1.9 5 1 5.9 1 7C1 8.1 1.9 9 3 9C3.85 9 4.57 8.47 4.86 7.72C4.91 7.73 4.95 7.75 5 7.75H7.57C5.52 9.27 4.25 11.79 4.25 14.5C4.25 14.67 4.26 14.83 4.28 15H4C3.17 15 2.5 15.67 2.5 16.5V18.5C2.5 19.33 3.17 20 4 20H6C6.22 20 6.42 19.95 6.61 19.86C7.13 19.64 7.5 19.11 7.5 18.5V16.5C7.5 15.67 6.83 15 6 15H5.77C5.77 14.97 5.78 14.94 5.78 14.91C5.76 14.77 5.76 14.64 5.76 14.5C5.76 12.03 7.03 9.77 9.02 8.6C9.06 9.37 9.71 10 10.5 10H13.5C14.29 10 14.94 9.37 14.99 8.6C16.98 9.77 18.25 12.04 18.25 14.5C18.25 14.64 18.24 14.77 18.23 14.91C18.23 14.94 18.24 14.97 18.24 15H18C17.17 15 16.5 15.67 16.5 16.5V18.5C16.5 19.11 16.87 19.64 17.39 19.86C17.58 19.95 17.78 20 18 20H20C20.83 20 21.5 19.33 21.5 18.5V16.5C21.5 15.67 20.83 15 20 15H19.72C19.74 14.83 19.75 14.67 19.75 14.5C19.75 11.79 18.48 9.27 16.43 7.75H19C19.05 7.75 19.09 7.73 19.14 7.72Z"
                                fill="#3B82F6"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="flex-1 p-3">
                          <h3 className="font-heading mb-2 text-xl text-gray-900 font-black">
                            開発プロセス改善
                          </h3>
                          <p className="text-sm text-gray-700">
                            開発プロセス全体からボトルネックになっているところを特定して、効率化のための技術支援を行います。開発環境の整備、開発メンバーのオンボーディング、CI/CD環境の整備、ビルドプロセスの効率化、アジャイル開発プロセスの導入など多岐に渡る施策を伴走して実現します。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-8">
                <img
                  className="mx-auto md:mr-0 rounded-3xl"
                  src="images/slidebean-iW9oP7Ljkbg-unsplash.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && <Modal setShowModal={setShowModal} />}
      <section className="py-10 bg-gray-50 overflow-hidden" id="contact">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 bg-white overflow-hidden rounded-3xl">
            <div className="max-w-5xl mx-auto">
              <div className="mb-10 md:max-w-md mx-auto text-center">
                <span className="inline-block mb-4 text-sm text-blue-500 font-bold uppercase tracking-widest">
                  Contact Us
                </span>
                <h2 className="font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-gray-900 font-black tracking-tight">
                  Get connected
                </h2>
                <p className="text-gray-500">
                  ご興味やお困りごとなど、どんなご要件でもお気軽にお知らせください。最適なご提案をお届けできるよう、お困りの点を詳しくご記入いただけると幸いです。お客様のビジネスの成長をサポートできることを心から楽しみにしています。
                </p>
              </div>
              <form className="p-10 bg-gray-100 border border-gray-100 rounded-3xl">
                <div className="flex flex-wrap -m-5 mb-1">
                  <div className="w-full md:w-1/2 p-5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-1"
                    >
                      Full name
                    </label>
                    <input
                      className="appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full"
                      id="contactLightReverseInput2-1"
                      type="text"
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="w-full md:w-1/2 p-5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-2"
                    >
                      Emaill address
                    </label>
                    <input
                      className="appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full"
                      id="contactLightReverseInput2-2"
                      type="text"
                      placeholder="Email address"
                      onChange={(e) => setMail(e.target.value)}
                      value={mail}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -m-5">
                  <div className="w-full md:w-1/2 p-5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-3"
                    >
                      Phone
                    </label>
                    <input
                      className="appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full"
                      id="contactLightReverseInput2-3"
                      type="text"
                      placeholder="Enter your phone number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </div>
                  <div className="w-full md:w-1/2 p-5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-4"
                    >
                      Subject
                    </label>
                    <input
                      className="appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full"
                      id="contactLightReverseInput2-4"
                      type="text"
                      placeholder="Type your subject"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -m-3.5">
                  <div className="w-full p-3.5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-5"
                    >
                      Message
                    </label>
                    <textarea
                      className="appearance-none px-6 py-5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-3xl"
                      id="contactLightReverseInput2-5"
                      rows={8}
                      placeholder="Enter your message"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                  </div>
                  <div className="w-full p-3.5">
                    <div className="flex flex-wrap items-center -m-2">
                      <div className="w-full md:w-1/2 p-2">
                        <div className="flex">
                          <input
                            className="opacity-0 absolute h-5 w-5"
                            id="contactLightReverseCheckbox2-1"
                            type="checkbox"
                            onChange={(e) => setAllowContact((prev) => !prev)}
                          />
                          <div className="flex flex-shrink-0 justify-center items-center w-6 h-6 mr-4 text-transparent bg-white border border-gray-200 rounded-md">
                            <svg
                              width={9}
                              height={7}
                              viewBox="0 0 9 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.603516 3.77075L2.68685 5.85409L7.89518 0.645752"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <label
                            className="text-sm text-gray-500 font-bold"
                            htmlFor="contactLightReverseCheckbox2-1"
                          >
                            I’d llike to occasionally receive other
                            communication from femtowork, such as content and
                            product news.
                          </label>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 p-2">
                        <div className="flex flex-wrap md:justify-end -m-2">
                          <div className="w-full md:w-auto p-2">
                            <a
                              className="block w-full px-8 md:px-16 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full"
                              onClick={handleSubmit}
                            >
                              Send Message
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="flex flex-wrap -m-4">
                <div className="w-full md:w-1/3 p-4"></div>
                <div className="w-full md:w-1/3 p-4"></div>
                <div className="w-full md:w-1/3 p-4"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 bg-gray-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 mb-8 bg-white rounded-3xl">
            <div className="max-w-7xl mx-auto">
              <div className="flex flex-wrap items-center justify-between -m-4">
                <div className="w-auto p-4">
                  <a className="inline-block" href="/">
                    <img src="images/femtowork-logo.png" />
                  </a>
                </div>
                <div className="w-auto p-4">
                  <ul className="-m-6">
                    <li className="inline-flex p-6">
                      <a
                        className="inline-block text-gray-500 hover:text-gray-600 font-bold"
                        href="#mission"
                      >
                        Mission
                      </a>
                    </li>
                    <li className="inline-flex p-6">
                      <a
                        className="inline-block text-gray-500 hover:text-gray-600 font-bold"
                        href="#solutions"
                      >
                        Solutions
                      </a>
                    </li>
                    <li className="inline-flex p-6">
                      <a
                        className="inline-block text-gray-500 hover:text-gray-600 font-bold"
                        href="#contact"
                      >
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-auto p-4">
                  <div className="flex flex-wrap -m-4">
                    <div className="w-auto p-4">
                      <a className="block" href="#" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center font-bold">
            <span>Ⓒ Copyright. All rights reserved by </span>
            <a className="text-blue-500 hover:text-blue-600" href="#">
              femtowork
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default Top;
